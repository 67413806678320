// Gatsby requirements
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'
import Popup from '../components/Popup/Popup'

// Storyblok Components
import StoryblokComponents from 'utils/StoryblokComponents'

const Page = ({ data }) => {

    const [previewStory, setPreviewStory] = useState(null);

    let story = useStoryblokState(data.storyblokEntry);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            setPreviewStory(null);
        }
    }, [story]);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            const url = `https://api.storyblok.com/v2/cdn/stories/${data.storyblokEntry.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
        
            const fetchData = async () => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setPreviewStory(json.story);
                } catch (error) {
                    console.log("error", error);
                }
            };
        
            fetchData();
        }
    }, []);

    return (
        <Layout slug={story.full_slug} footerData={data.footer} headerData={data.header}>
            <main id="content" {...storyblokEditable(story.content)}>
                <StoryblokComponents content={previewStory ? previewStory?.content?.content : story.content?.content}/>
            </main>

            {data.popup  &&
                <Popup data={data.popup} />
            }
        </Layout>
    )
}

export const Head = ({ data }) => {
    const content = data.storyblokEntry.content?.title ? data.storyblokEntry.content : JSON.parse(data.storyblokEntry.content);
    return (
        <Seo
            title={content?.title}
            description={content?.description}
            url={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry.full_slug}`}
            noindex={content?.noindex}
            type="website"
        />
    )
}

export const query = graphql`
  query ($full_slug: String!, $header_full_slug: String!, $footer_full_slug: String!, $popupPath: String) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
        full_slug
        name
        content
        id
        uuid
        internalId
    }
    header: storyblokEntry(full_slug: { eq: $header_full_slug }) {
        content
        id
        uuid
        internalId
    }
    footer: storyblokEntry(full_slug: { eq: $footer_full_slug }) {
        content
        id
        uuid
        internalId
    }
    popup: storyblokEntry(full_slug: { eq: $popupPath }) {
        content
        id
        uuid
        internalId
    }
  }
`

export default Page
