// Gatsby Requirements
import React, { useState, useEffect } from 'react'

// Style
import * as CSS from './Popup.module.scss'

// Components
import RichText from "utils/RichTextRenderer"
import Image from "utils/Image"


const Popup = ({ data, previewData }) => {

    const [popupIsOpen, setPopupIsOpen] = useState(true);
    const [hideThisSession, setHideThisSession] = useState(true);
    const [hideForever, setHideForever] = useState(true);

    // Storyblok connection
    const blok = previewData ? previewData?.content : JSON.parse(data?.content);

    // Check if popup is hidden forever
    useEffect(() => {
        setTimeout(() => {
            if (localStorage.getItem('hideForever') === "true") {
                setHideForever(true);
            } else {
                setHideForever(false);
            }
        }, blok?.secondsToWaitBeforePopupShows * 1000);
    }, [])

    // Check if popup is hidden this session
    useEffect(() => {
        setTimeout(() => {
            if (sessionStorage.getItem('hideThisSession') === "true") {
                setHideThisSession(true);
            } else {
                setHideThisSession(false);
            }
        }, blok?.secondsToWaitBeforePopupShows * 1000);
    }, [])

    
    // Focus on popup
    useEffect(() => {
        if (popupIsOpen && !hideThisSession && !hideForever) {
            setTimeout(() => {
                document.getElementById("popup").focus();
            }, 500);
        }
    }, [hideThisSession])

    // Hide popup for this session
    function functionHideThisSession() {
        sessionStorage.setItem('hideThisSession', "true");
    }

    return (
        ((data && popupIsOpen && !hideThisSession && !hideForever) || (data && previewData)) &&
            <aside className={`${CSS.section}`} id="popup" tabindex="0" aria-label="Popup">
                <section>
                    <div className={`
                        ${CSS.wrapper}
                        ${blok?.design === 'design-2' ? CSS.design2 : ''}
                        ${blok?.design === 'design-3' ? `${CSS.design2} ${CSS.design3}` : ''}
                    `}>
                        <div className={CSS.image}>
                            <Image className={`${CSS.backgroundImage}`} mediumwidth img={blok?.image}/>
                        </div>
                        <div className={`${CSS.text}`}>
                            <RichText popup data={blok?.text} />
                        </div>
                        <button 
                            onClick={() => { setPopupIsOpen(false); functionHideThisSession() }}
                            aria-label="Close popup"
                            className={CSS.close}
                        >
                            &#10005;
                        </button>
                    </div>
                </section>
            </aside>
    )
}

export default Popup